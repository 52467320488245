import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Link from '../link';

const NavbarItemTitle = styled(Link)`
    ${({ theme }) => css`
        background: transparent;
        border: 0;
        font-weight: 500;
        font-family: inherit;
        font-size: 16px;
        padding: ${theme.global.edgeSize.xsmall} ${theme.global.edgeSize.small};
        color: #fff;
        background-color: #222;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        transition: opacity 250ms;
        cursor: pointer;
        text-decoration: none;
        /* position above the dropdown, otherwise the dropdown will cover up the bottom sliver of the buttons */
        position: relative;

        &.is-active,
        &:hover,
        &:focus {
            text-decoration: none;
            outline: none;
            background-color: ${theme.global.colors.brand.light};
            color: #fff;
        }
    `}
`;

const NavbarItemEl = styled.li`
    /* position: relative; */
`;

interface Props {
    title: string;
    index: number;

    to: string;
}

const NavbarItem: FC<Props> = props => {
    const { title, to } = props;

    return (
        <NavbarItemEl>
            <NavbarItemTitle to={to} activeClassName="is-active">
                {title}
            </NavbarItemTitle>
        </NavbarItemEl>
    );
};
export default NavbarItem;
