import styled from 'styled-components';

import { Box } from 'grommet';
import { media } from './media';

export const StyledStep = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    margin: 0;
    max-width: 1200px;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    position: relative;
    padding: 0 1rem;
`;

export const StyledAction = styled(Box)`
    width: 100%;
    text-align: left;
    margin: 0;
    background-color: ${(props) => props.theme.global.colors['light-1']};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem;

    .cartoon {
        display: none;
    }

    .action-content {
        max-width: 1200px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;

        ${media.lg`
    flex-direction: row;
 
    `}
    }

    .action-actions {
        display: flex;
        justify-content: row;

        & > * {
            align-self: center;
        }
        & > * + * {
            margin-left: 1rem;
        }
    }

    .action-inputs {
        & > * {
            margin-right: 1rem;
            margin-bottom: 1.5rem;
            width: 100%;

            ${media.md`
      width: auto;
    `}
            ${media.lg`
    margin-bottom: 0;
    `}
        }
    }
`;
