import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MaxWidth } from '../maxWidth';

const NavbarEl = styled.nav`
    ${({ theme }) => css`
    
        margin: auto;
        z-index: 11;
        min-height: 60px;
        background-color: #222;
        padding ${theme.global.edgeSize.small} 0;
        @media(max-width: ${theme.global.breakpoints.medium.value - 1}px) {
            display: none;
        }

    `}
`;

const NavbarList = styled.ul`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;

        * + * {
            margin-left: ${theme.global.edgeSize.small};
        }
    `}
`;

const Navbar: FC<any> = ({ children, onMouseLeave }) => (
    <NavbarEl onMouseLeave={onMouseLeave}>
        <MaxWidth>
            <NavbarList>{children}</NavbarList>
        </MaxWidth>
    </NavbarEl>
);

export default Navbar;
