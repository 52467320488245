import { css } from 'styled-components';

export const mediaSizes = {
    xxl: 1350,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 360,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(mediaSizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${mediaSizes[label]}px) {
            ${css(...args)}
        }
    `;
    return acc;
}, {});

export default media;
