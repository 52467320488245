import { Box, Button as GButton, Text } from 'grommet';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { HeaderPopupContext } from '../context';
import Button from './button';
import { TagButton } from './TagButton';

interface actionBUttonsProps {}

const ActionButtons: FC<actionBUttonsProps> = () => {
    const { onOpen } = useContext(HeaderPopupContext);

    return (
        <>
            <Box direction="row" gap="medium" align="center" justify="center">
                {/* <Button
                    label={<Text color="white">Get an online quote</Text>}
                    to="/online-quote"
                    color="accent-1"
                    primary
                    reverse
                    alignSelf="start"
                />

                <GButton
                    label={<Text color="white">Book an appointment</Text>}
                    color="brand"
                    primary
                    reverse
                    alignSelf="start"
                    onClick={onOpen}
                /> */}
                <TagButton
                    alignSelf="start"
                    primary
                    label={
                        <>
                            <Text color="black" textAlign="center">
                                Get a FREE quote
                            </Text>
                        </>
                    }
                    onClick={onOpen}
                    color="accent-1"
                />
            </Box>
        </>
    );
};

export default ActionButtons;
