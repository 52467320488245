import styled, { css } from 'styled-components';

import media from './media';

export const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-items: center;
    padding: 1.5rem 1rem;

    ${media.md`
  gap: 1.5rem;
  `}
    ${media.lg`
  max-width: 700px;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  `}
`;

export const StyledOptions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-items: center;
    padding: 1.5rem 1rem;

    ${media.md`
  gap: 1.5rem;
  `}
    ${media.lg`
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  `}
`;

export const StyledOption = styled.button`
  border: 0;
  padding: 1rem;
  background-color: white;
  border-radius: 50%;
  border: 0.5rem solid #F2F2F2;
  width: 140px;
  height: 140px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  ${media.md`
  width: 260px;
  height: 260px;
  border-width: .85rem;
  padding: 2.5rem;
  `}
  ${media.lg`
  width: 280px;
  height: 280px;
  border-width: 1rem;
  padding: 3rem;
  `}
  ${media.xl`
  width: 300px;
  height: 300px;
  `}

  &:hover {
    border-color: #3A3A3A;

  }
  &.is-active {
    border-color: ${props => props.theme.global.colors.brand.light};
  }

  .label {
    margin-top: 10px;
    display: none;
  ${media.lg`
  display: block;
  `}
  }


  img {
    width: 100%;
    height: auto;
  }

  ${props =>
      props.expand &&
      css`
          position: relative;
          .gatsby-image-wrapper,
          img {
              position: absolute !important;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
              overflow: hidden;
          }

          .label {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              background-color: rgba(255, 255, 255, 0.95);
              border-radius: 4px;
              padding: 10px;
              margin: 0;
              display: block;
              font-weight: bold;

              @media (min-width: 768px) {
              }
          }
      `}
`;
