import { css } from 'styled-components';

export const styledBaseSection = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;

    .content {
        width: 100%;
        max-width: 1200px;
        padding: 1.5rem 1rem;
    }
`;
