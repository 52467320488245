import React, { FC } from 'react';
import { Button as GButton } from 'grommet';
import { replaceSiteUrl, isExternal } from '../utils';
import { AdapterLink } from './adapterLink';

interface ButtonProps {
    to: string;
    label: any;
    reverse?: boolean;
    icon?: any;
    onClick?: any;
}
const Button: FC<ButtonProps> = ({ to, label, reverse = false, icon, ...rest }) => {
    const toLink = replaceSiteUrl(to);

    let as: any = AdapterLink;

    if (rest.onClick) {
        as = 'button';
    } else if (isExternal(toLink)) {
        as = 'a';
    }

    return <GButton href={toLink} as={as} {...rest} label={label} reverse={reverse} />;
};

export default Button;
