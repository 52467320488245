import React from 'react';

import { Heading } from 'grommet';
import { Alert, StyledStep } from '../styles';

const StepComplete = () => (
    <StyledStep>
        <Heading>Thank you</Heading>
        <Alert>Your enquiry has been received and a team member will contact you shortly to discuss.</Alert>
    </StyledStep>
);

export default StepComplete;
