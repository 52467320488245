import { Button } from 'grommet';
import styled from 'styled-components';

export const TagButton = styled(Button)`
    position: relative;
    .the-tag {
        margin: 0;
        position: absolute;

        right: -10px;
        transform: rotate(-8deg);
        color: white;
        font-weight: 700;
        font-style: italic;
        background-color: red;
        padding: 2px;
        border-radius: 4px;
        pointer-events: none;
        bottom: -22px;
        z-index: 8;
    }
`;
