/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Footer as GFooter, Text, Box } from 'grommet';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Link from './link';

import MaxWidth from './maxWidth';
import { createMarkup } from '../utils';
import { CompanyInfoProps } from '../context';
import HtmlContent from './htmlContent';

const StyledAddress = styled(Text)`
    text-align: center;
    color: #fff;
`;

const StyledNav = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    text-align: center;
    justify-content: center;

    li a {
        font-size: 80%;
    }
`;

const StyledUpperNav = styled(StyledNav)`
    max-width: 380px;
    align-self: center;
`;

const StyledNavItem = styled.li`
    ${({ theme }) => css`
        text-align: inherit;
        padding: 0 ${theme.global.edgeSize.xsmall};
    `}
`;

interface Props {
    companyInfo: CompanyInfoProps;
}

const Footer: FC<Props> = ({ companyInfo }) => {
    const {
        upperMenu: { menuItems },
    } = useStaticQuery(graphql`
        query FooterNavQuery {
            upperMenu: wpMenu(slug: { eq: "footer-menu" }) {
                menuItems {
                    nodes {
                        url
                        label
                        target
                        id
                    }
                }
            }
        }
    `);

    return (
        <>
            <GFooter background={{ color: 'dark-1', dark: true }} justify="center" pad="small" direction="column">
                <MaxWidth
                    direction="column"
                    gap="medium"
                    pad={{ top: 'medium', bottom: 'medium' }}
                    justify="center"
                    textAlign="center"
                >
                    {companyInfo && (
                        <StyledAddress as="address">
                            <strong>{companyInfo.companyName}</strong>,{' '}
                            <span
                                dangerouslySetInnerHTML={createMarkup(
                                    companyInfo.address.replace(/(\r\n|\n|\r)/gm, '<br />')
                                )}
                            />
                            , {companyInfo.town},{companyInfo.county},{companyInfo.postcode}
                        </StyledAddress>
                    )}
                </MaxWidth>
            </GFooter>

            {companyInfo && companyInfo.footerText && (
                <MaxWidth>
                    <Box pad={{ bottom: 'medium' }}>
                        <Text size="small">
                            <HtmlContent
                                textAlign="center"
                                dangerouslySetInnerHTML={createMarkup(companyInfo.footerText)}
                            />
                        </Text>
                        <Box flex={{ shrink: 0, grow: 0 }}>
                            {menuItems && menuItems.nodes && (
                                <StyledUpperNav>
                                    {menuItems.nodes.map(({ label, url, id }, i) => (
                                        <StyledNavItem key={id}>
                                            {i >= 1 && <Text> | </Text>}
                                            <Link to={url}>{label}</Link>
                                        </StyledNavItem>
                                    ))}
                                </StyledUpperNav>
                            )}
                        </Box>
                    </Box>
                </MaxWidth>
            )}
        </>
    );
};

export default Footer;
