import React, { FC, useContext } from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import styled, { css } from 'styled-components';
import { CompanyContext, HeaderPopupContext } from '../context';
import Button from './button';
import { TagButton } from './TagButton';
import { Trustpilot } from './Trustpilot';

const StyledPhone = styled(Text)`
    a {
        color: #222;
        text-decoration: none;
    }
`;

const StyledCtaWrap = styled(Box)`
    ${() => css`
        flex-direction: column;
        > * + * {
            margin-top: 12px;
        }
        /* @media (min-width: 500px) {
            flex-direction: row;
            > * + * {
                margin-top: 0;
                margin-left: 12px;
            }
        } */
    `}
`;
interface HeaderCtaProps {}

const HeaderCta: FC<HeaderCtaProps> = () => {
    const { phoneNumber } = useContext(CompanyContext);
    const size = useContext(ResponsiveContext);
    const { onOpen } = useContext(HeaderPopupContext);
    const align = size === 'large' ? 'end' : 'center';

    return (
        <Box
            direction="column"
            gap="medium"
            justify="center"
            align={align}
            alignContent={align}
            style={size === 'large' ? { marginLeft: 'auto' } : {}}
        >
            <StyledPhone size="xlarge" weight="bold">
                {phoneNumber}
            </StyledPhone>

            <StyledCtaWrap>
                <TagButton
                    primary
                    label={
                        <>
                            <Text color="black" textAlign="center">
                                Get a FREE quote
                            </Text>
                        </>
                    }
                    onClick={onOpen}
                    color="accent-1"
                />
                {/* <Trustpilot /> */}
                {/* <StyledButton
                    primary
                    label={
                        <>
                            <Text color="white" textAlign="center">
                                Get an online quote
                            </Text>
                        </>
                    }
                    to="/online-quote"
                    color="accent-1"
                /> */}
            </StyledCtaWrap>
        </Box>
    );
};

export default HeaderCta;
