import React, { useContext } from 'react';
import { Layer, Box, Heading, Button } from 'grommet';
import { Close } from 'grommet-icons';
import { HeaderPopupContext } from '../context';
import Quote from './quote';

export const HeaderFrom = () => {
    const { open, onClose } = useContext(HeaderPopupContext);
    return (
        <>
            {open && (
                <Layer position="center" responsive modal margin="large" onClickOutside={onClose} onEsc={onClose}>
                    <Box as="form" fill="vertical" overflow="auto" pad="medium" onSubmit={onClose}>
                        <Box flex={false} direction="row" justify="end">
                            <Button icon={<Close />} onClick={onClose} />
                        </Box>
                        <Box flex="grow" overflow="auto" pad={{ bottom: 'medium' }}>
                            <Quote />
                        </Box>
                    </Box>
                </Layer>
            )}
        </>
    );
};
