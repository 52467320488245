import React from 'react';

import { Heading, TextInput, Button, Select, TextArea, Text } from 'grommet';
import { Alert, StyledStep, StyledAction, StyledForm } from '../styles';

import builder from '../../images/builder.png';

const Step3 = ({ complete, handleChange, isValid, fullForm, page }) => {
    const { formValid } = isValid;

    return (
        <>
            <StyledStep>
                <Heading level={2}>Your details</Heading>

                <StyledForm gap="medium">
                    <TextInput
                        name="firstname"
                        type="name"
                        placeholder="Name"
                        onChange={handleChange('firstname')}
                        value={fullForm.firstname}
                        required
                    />

                    <TextInput
                        name="email"
                        type="name"
                        placeholder="Email"
                        onChange={handleChange('email')}
                        value={fullForm.email}
                        required
                    />

                    <TextInput
                        name="phone"
                        type="text"
                        placeholder="Contact number"
                        onChange={handleChange('phone')}
                        value={fullForm.phone}
                        required
                    />

                    <TextInput
                        name="street"
                        type="text"
                        placeholder="Street"
                        onChange={handleChange('street')}
                        value={fullForm.street}
                        required
                    />

                    <TextInput
                        name="town"
                        type="text"
                        placeholder="Town"
                        onChange={handleChange('town')}
                        value={fullForm.town}
                        required
                    />
                    <TextInput
                        name="postcode"
                        type="text"
                        placeholder="Postcode"
                        onChange={handleChange('postcode')}
                        value={fullForm.postcode}
                        required
                    />

                    <TextArea
                        name="message"
                        value={fullForm.message}
                        onChange={handleChange('message')}
                        rows={6}
                        placeholder="Message"
                    />

                    {/* How did you find us? */}

                    <Select
                        placeholder="How did you find us?"
                        name="conservatorySolution"
                        options={[
                            'Recommendation',
                            'Facebook',
                            'Radio',
                            'TV',
                            'Publications',
                            'Vans/Installation boards',
                            'Internet',
                            'Brand awareness',
                            'Other',
                        ]}
                        value={fullForm.how}
                        onChange={({ option }) => handleChange('how')(option)}
                    />
                </StyledForm>
            </StyledStep>

            <StyledAction>
                <div className="action-content">
                    <div className="action-inputs">
                        {!formValid ? (
                            <Alert>Please complete all required fields</Alert>
                        ) : (
                            <Alert>Click request a quote to finish</Alert>
                        )}
                    </div>
                    <div className="action-actions">
                        <Button
                            type="button"
                            color="brand"
                            primary
                            onClick={complete}
                            disabled={!formValid}
                            label={<Text color="white">Request FREE quote</Text>}
                        />
                    </div>
                </div>
            </StyledAction>
        </>
    );
};

export default Step3;
