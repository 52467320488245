import React from 'react';
import Navbar from './Navbar';
import NavbarItem from './Navbar/NavbarItem';
import { replaceSiteUrl } from '../utils';

const StaticNavbar = ({ items }) => {
    const nav = items.map(item => {
        const theItem = { title: item.label, to: replaceSiteUrl(item.url) };

        return theItem;
    });

    return (
        <Navbar>
            {nav.map((n, index) => (
                <NavbarItem key={n.title} title={n.title} index={index} to={n.to} />
            ))}
        </Navbar>
    );
};

export default StaticNavbar;
