import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';

const StyledBox = styled(Box)`
    position: ${props => (props.relative ? 'relative' : 'static')};
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    padding-left: ${props => props.theme.global.edgeSize.medium
};
    padding-right: ${props => props.theme.global.edgeSize.medium
};

    @media (min-width: 1200px) {
        max-width: 1120px;
    }
    @media (min-width: 1440px) {
        max-width: 1400px;
    }
`;

export const MaxWidth: FC<any> = ({ children, relative = true, ...props }) => (
    <StyledBox relative={relative} {...props}>
        {children}
    </StyledBox>
);

export default MaxWidth;
