import { Box, Heading, ResponsiveContext, Button as GButton } from 'grommet';
import React, { FC, useContext } from 'react';
import { Close } from 'grommet-icons';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { createMarkup } from '../utils';
import Button from './button';
import HtmlContent from './htmlContent';
import { MaxWidth } from './maxWidth';

const StyledClose = styled(GButton)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
`;
const StyledContent = styled(HtmlContent)`
    > *:first-child {
        margin-top: 5px;
    }
`;
interface BannerProps {
    buttonText?: string;
    content?: string;
    heading?: string;
    buttonLinkUri?: string;
    show?: boolean;
}

const Banner: FC<BannerProps> = ({ buttonText, content, heading, show, buttonLinkUri: buttonUri }) => {
    const size = useContext(ResponsiveContext);

    const [cookies, setCookie] = useCookies(['hide_banner']);

    if (!show || (cookies && cookies.hide_banner === 'yes')) {
        return null;
    }

    const expires = new Date();
    expires.setDate(expires.getDate() + 1);
    const cookieOpts = { expires, sameSite: 'strict', path: '/' };

    return (
        <Box background={{ color: 'dark-2', dark: true }}>
            <MaxWidth
                direction="row-responsive"
                justify="start"
                pad={{ vertical: 'small', right: 'medium' }}
                gap="medium"
            >
                <Box justify="center" direction={size === 'small' ? 'column' : 'row'} gap="medium">
                    <Heading
                        color="accent-2"
                        level={3}
                        as="span"
                        textAlign={size === 'small' ? 'center' : 'start'}
                        margin="none"
                    >
                        {heading}
                    </Heading>

                    {content && (
                        <StyledContent
                            dangerouslySetInnerHTML={createMarkup(content)}
                            textAlign={size === 'small' ? 'center' : 'start'}
                        />
                    )}
                </Box>

                {buttonText && buttonUri && (
                    <Button alignSelf="center" to={buttonUri} label={buttonText} color="light-1" reverse size="small" />
                )}

                <StyledClose
                    icon={<Close size="medium" />}
                    a11yTitle="Close banner"
                    plain
                    onClick={() => setCookie('hide_banner', 'yes', cookieOpts)}
                />
            </MaxWidth>
        </Box>
    );
};

export default Banner;
