import { graphql, Link, useStaticQuery } from 'gatsby';
import { Box, Header as GHeader, ResponsiveContext } from 'grommet';
import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { CompanyInfoProps } from '../context';
import LogoIcon from '../images/leo-logo.svg';
import HeaderCta from './headerCta';
import { HeaderFrom } from './HeaderFrom';
import { MaxWidth } from './maxWidth';
import MobileNavbar from './mobileNavbar';
import StaticNavbar from './staticNavbar';

interface HeaderProps {
    siteTitle?: string;
    companyInfo: CompanyInfoProps;
}

const StyledLogo = styled(Box)`
    /* ${props => console.log(props.theme)} */

    width: 140px;
    flex-shrink: 0;
    @media (min-width: 340px) {
        width:190px;
    }
    @media (min-width: 1024px) {
        width:200px;
    }
    @media (min-width: 1300px) {
        width: 220px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
   
`;

const StyledHeader = styled(Box)`
    ${({ theme }) => css`
        flex-direction: column;

        .header-inner {
            width: 100%;
        }
        @media (min-width: ${theme.global.breakpoints.medium.value}px) {
            flex-direction: row;
            .header-inner {
                width: auto;
            }
        }
    `}
`;

const Header: FC<HeaderProps> = ({ companyInfo }) => {
    const size = useContext(ResponsiveContext);
    // console.log(size);
    const {
        wpMenu: { menuItems },
    } = useStaticQuery(graphql`
        query NavQuery {
            wpMenu(slug: { eq: "header-menu" }, parent: { id: { eq: null } }) {
                menuItems {
                    nodes {
                        url
                        label
                        target
                        id
                        parentId
                        menuItemExtras {
                            featuredTitle
                            featuredContent {
                                ... on WpPage {
                                    featuredImage {
                                        node {
                                            sourceUrl
                                            mediaItemUrl
                                            altText
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 300, height: 280, quality: 80, layout: FIXED)
                                                }
                                            }
                                        }
                                    }
                                    uri
                                    title
                                }
                            }
                        }
                        childItems {
                            nodes {
                                url
                                label
                                id
                                childItems {
                                    nodes {
                                        url
                                        label
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const menuItemsFiltered = menuItems.nodes.filter(item => !item.parentId);

    // pad={{ top: 'large', bottom: 'large' }}
    return (
        <>
            <GHeader
                justify="center"
                background={{
                    color: '#fff',
                }}
            >
                <MaxWidth>
                    <StyledHeader
                        pad={{ top: 'large', bottom: 'large' }}
                        flex={{ shrink: 0 }}
                        justify="start"
                        align="center"
                        gap="medium"
                    >
                        <Box direction="row" flex="grow" className="header-inner">
                            <StyledLogo>
                                <Link
                                    to="/"
                                    style={{
                                        color: `black`,
                                        textDecoration: `none`,
                                    }}
                                >
                                    <img src={LogoIcon} alt={companyInfo.companyName} />
                                </Link>
                            </StyledLogo>
                            {size !== 'large' && <MobileNavbar items={menuItemsFiltered} />}
                        </Box>

                        <HeaderCta />
                    </StyledHeader>
                </MaxWidth>
            </GHeader>
            <HeaderFrom />
            <StaticNavbar items={menuItemsFiltered} />
        </>
    );
};

export default Header;
