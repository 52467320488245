import React from 'react';

import styled from 'styled-components';
import { Heading, Box } from 'grommet';
import { media, styledBaseSection } from './styles';
import step1 from '../images/icon-call.svg';
import step2 from '../images/icon-measure.svg';
import step3 from '../images/icon-style.svg';
import step4 from '../images/icon-call.svg';
import ActionButtons from './actionButtons';

const StyledStepsSection = styled(Box)`
    ${styledBaseSection}
    background-color: #868585;
    color: #fff;
    position: relative;
    .block {
        text-align: center;
        max-width: 1000px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        ${media.xxl`
    align-items: center;
    `}
    }

    .image {
        position: absolute;
        max-width: 430px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;

        ${media.xl`
      display: block;
    `}
    }
`;

const StyledSteps = styled.section`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 30px auto;
    max-width: 600px;

    ${media.lg`
    grid-template-columns: 1fr 1fr 1fr;
    `}

    & > div {
        flex: 1 1 calc(32% - 10px);
        margin: 0 5px;
        ${media.lg`
        flex: 1 1 calc(32% - 10px);
        `}

        @supports (display: grid) {
            margin: 0;
            ${media.lg`
            margin: 0;
            `}
        }
    }
`;

const StyledStep = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;

    img {
        margin-bottom: 1.75rem;
        max-width: 100%;
        height: auto;
    }
`;

const Step = ({ icon, text }) => (
    <StyledStep>
        <img src={icon} alt={text} />
        <strong>{text}</strong>
    </StyledStep>
);

const Steps = ({ heading }) => (
    <StyledStepsSection>
        <div className="content">
            <div className="block">
                <Heading color="white">3 easy steps to enjoy your conservatory all year round!</Heading>
                <StyledSteps>
                    <Step icon={step1} text="Enter contact details" />
                    <Step icon={step2} text="Arrange quotation" />
                    <Step icon={step3} text="Leave the rest to us" />
                </StyledSteps>

                <Box pad={{ bottom: 'medium' }}>
                    <ActionButtons />
                </Box>
            </div>
        </div>
    </StyledStepsSection>
);

export default Steps;
