import styled from 'styled-components';

export const Alert = styled.div`
    background-color: #fff;
    border-left: 10px solid #ccc;
    font-size: 1rem;
    padding: 0.875rem 1.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
`;
