import React, { FC, useState, useContext } from 'react';
import { FormClose, Phone } from 'grommet-icons';
import { Box, Button, Layer, Anchor, Text } from 'grommet';
import styled, { css } from 'styled-components';
import { HeaderPopupContext, CompanyContext } from '../context';
import Link from './link';
import HeaderCta from './headerCta';
import Menu from '../images/icons/menu.svg';

const StyledMobileAction = styled(Box)`
    ${({ theme }) => css`
        @media (min-width: ${theme.global.breakpoints.medium.value}px) {
            display: none;
        }
    `}
`;

const StyledLayer = styled(Layer)`
    ${({ active, theme }) => css`
        display: ${active ? 'block' : 'none'};
    `}
`;

const StyledMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;
const StyledSubMenuList = styled.ul`
    ${({ active, theme }) => css`
        display: ${active ? 'block' : 'none'};
        background-color: ${theme.global.colors['light-3']};
        list-style: none;
        padding: ${theme.global.edgeSize.medium} 0;
        margin: 0;
    `}
`;

const StyledMenuItem = styled.li`
    ${({ theme, sub }) => css`
        a,
        .link {
            text-decoration: none;
            display: block;
            padding: ${theme.global.edgeSize.small} ${theme.global.edgeSize.medium};
            color: ${theme.global.colors.text.light};

            &:hover {
                background-color: ${theme.global.colors['light-2']};
            }
        }
    `}
`;

const MenuItem = ({ url, label, id, childItems }) => {
    const { onCloseMobile } = useContext(HeaderPopupContext);

    const [show, setShow] = useState(false);
    const handleToggle = (e) => {
        e.preventDefault();
        setShow(!show);
    };

    return (
        <StyledMenuItem>
            {childItems && childItems.nodes.length >= 1 ? (
                <>
                    <Anchor href={url} className="link" onClick={(e) => handleToggle(e)}>
                        {label}
                    </Anchor>

                    <StyledSubMenuList active={show}>
                        {childItems.nodes.map((item) => (
                            <StyledMenuItem key={item.id}>
                                <Link to={item.url} className="link sub-link" onClick={onCloseMobile}>
                                    {item.label}
                                </Link>
                            </StyledMenuItem>
                        ))}
                    </StyledSubMenuList>
                </>
            ) : (
                <Link to={url} className="link" onClick={onCloseMobile}>
                    {label}
                </Link>
            )}
        </StyledMenuItem>
    );
};

interface Props {
    items: any[];
}
const MobileNavbar: FC<Props> = ({ items }) => {
    const { onCloseMobile, onOpenMobile, openMobile, onOpen } = useContext(HeaderPopupContext);
    const { phoneNumber } = useContext(CompanyContext);

    const handleToggleSidebar = () => {
        if (openMobile) {
            onCloseMobile();
        } else {
            onOpenMobile();
        }
    };

    const handleCallBack = () => {
        onCloseMobile();
        onOpen();
    };
    return (
        <>
            <StyledMobileAction
                direction="row"
                gap="small"
                margin={{ left: 'auto' }}
                background={{ dark: false }}
                align="start"
            >
                {/* <Text size="xlarge" weight="bold">
                    {phoneNumber}
                </Text> */}
                <Button
                    onClick={handleToggleSidebar}
                    style={{ display: 'flex' }}
                    icon={
                        <>
                            <Text color="white" margin={{ right: 'small' }}>
                                Menu
                            </Text>
                            <Menu />
                        </>
                    }
                    primary
                    color="dark-1"
                />
            </StyledMobileAction>
            {openMobile && (
                <StyledLayer full active={openMobile} modal>
                    <Box align="end">
                        <Button icon={<FormClose size="large" />} onClick={handleToggleSidebar} />
                    </Box>
                    <Box>
                        <StyledMenuList>
                            {items.map((item) => (
                                <MenuItem {...item} key={item.id} />
                            ))}
                        </StyledMenuList>
                    </Box>
                    <Box pad={{ top: 'large', bottom: 'medium', left: 'medium', right: 'medium' }}>
                        <HeaderCta />
                    </Box>
                </StyledLayer>
            )}
        </>
    );
};

export default MobileNavbar;
