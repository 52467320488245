import styled from 'styled-components';
import { Box } from 'grommet';

const borderRadius = '8px';
export const Input = styled.input`
    border-radius: ${borderRadius};
    border: 1px solid ${props => props.theme.grey};
    background-color: ${props => props.theme.white};
    font-size: 1rem;
    padding: 0.875rem 1rem;
`;

export const Select = styled.select`
    border-radius: ${borderRadius};
    border: 1px solid ${props => props.theme.grey};
    background-color: ${props => props.theme.white};
    font-size: 1rem;
    padding: 0.875rem 1rem;
`;

export const Textarea = styled.textarea`
    border-radius: ${borderRadius};
    border: 1px solid ${props => props.theme.grey};
    background-color: ${props => props.theme.white};
    font-size: 1rem;
    padding: 0.875rem 1rem;
`;

export const StyledForm = styled(Box)`
    max-width: 500px;
    width: 100%;
`;
