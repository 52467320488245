import React from 'react';
import { Anchor } from 'grommet';
import { replaceSiteUrl, isExternal } from '../utils';
import { AdapterLink } from './adapterLink';

const Link = ({ to, ...rest }) => {
    const toLink: string = replaceSiteUrl(to)
        .replace('solution_type', 'solutions/type')
        .replace('integration_type', 'integrations/type');

    return <Anchor href={toLink} as={isExternal(toLink) ? 'a' : AdapterLink} {...rest} />;
};

export default Link;
