/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
 import { loadableReady } from '@loadable/component';
 import { hydrate } from 'react-dom';
 import React from 'react';


export const onClientEntry = async () => {
    if (typeof IntersectionObserver === 'undefined') {
        await import('intersection-observer');
        console.log('IntersectionObserver polyfilled ');
    }
};

// export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//     const { pathname } = location;
//     // list of routes for the scroll-to-top-hook
//     const scrollToTopRoutes = [`/privacy-policy`, `/online-quote`];
//     // if the new route is part of the list above, scroll to top (0, 0)
//     if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//         window.scrollTo(0, 0);
//     }

//     return false;
// };




export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    hydrate(element, container, callback)
  })
}

// export const onRenderBody = ({ setPostBodyComponents, setHeadComponents }, pluginOptions) => {

//   // Set script and style tags at the end of the document to parse the assets.
//   setPostBodyComponents([  <script
//     key="live-chat-tawk"
//     type="text/javascript"  crossOrigin="*" async={true} src="https://embed.tawk.to/606f2edbf7ce182709386be9/1f2p4u28l"/>
//   ])

// }
