import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { grommet } from 'grommet/themes';
import { css } from 'styled-components';

const base = {
    name: 'morespace',
    rounding: 8,
    spacing: 24,
    defaultMode: 'light',
    global: {
        breakpoints: {
            large: {
                value: 1200,
            },
            medium: {
                value: 992,
            },
            small: {
                value: 600,
            },
        },
        colors: {
            focus: '#0887BE',
            brand: {
                dark: '#0887BE',
                light: '#0887BE',
            },
            'dark-1': '#222222',
            'light-2': '#f2f2f2',
            'accent-1': {
                dark: '#F4E346',
                light: '#F4E346',
            },
            'accent-2': {
                dark: '#fff',
                light: '#222',
            },
            background: {
                dark: '#111111',
                light: '#FFFFFF',
            },
            'background-back': {
                dark: '#111111',
                light: '#FFFFFF',
            },
            'background-front': {
                dark: '#222222',
                light: '#FFFFFF',
            },
            'background-contrast': {
                dark: '#0887BE',
                light: '#0887BE',
            },
            text: {
                dark: '#222',
                light: '#222',
            },
            'text-strong': {
                dark: '#FFFFFF',
                light: '#000000',
            },
            'text-weak': {
                dark: '#CCCCCC',
                light: '#444444',
            },
            'text-xweak': {
                dark: '#999999',
                light: '#666666',
            },
            border: {
                dark: '#444444',
                light: '#CCCCCC',
            },
            control: 'brand',
            'active-background': 'background-contrast',
            'active-text': 'text-strong',
            'selected-background': 'brand',
            'selected-text': 'text-strong',
            'status-critical': '#FF4040',
            'status-warning': '#FFAA15',
            'status-ok': '#00C781',
            'status-unknown': '#CCCCCC',
            'status-disabled': '#CCCCCC',
            'graph-0': 'brand',
            'graph-1': 'status-warning',
        },
        font: {
            family: 'nunito',
        },
        active: {
            background: 'active-background',
            color: 'active-text',
        },
        hover: {
            background: 'active-background',
            color: 'active-text',
        },
        selected: {
            background: 'selected-background',
            color: 'selected-text',
        },
        control: {
            border: {
                radius: '8px',
            },
        },
        drop: {
            border: {
                radius: '8px',
            },
        },
    },
    // box: {
    //     responsiveBreakpoint: 'large',
    // },
    list: {
        extend: props => css`
            text-align: center;
        `,
        item: {
            extend: props => css`
                text-align: center;
            `,
        },
    },
    chart: {},
    diagram: {
        line: {},
    },
    meter: {},
    heading: {
        weight: 300,
        color: { dark: 'light-1', light: 'dark-1' },
        extend: props => css`
            /* margin-top: 0; */
        `,
    },
    button: {
        padding: {
            horizontal: '12px',
        },
        border: {
            radius: '8px',
        },
        extend: props => css``,
    },
    checkBox: {
        check: {
            radius: '8px',
        },
        toggle: {
            radius: '8px',
        },
    },
    radioButton: {
        check: {
            radius: '8px',
        },
    },
    formField: {
        border: {
            color: 'border',
            error: {
                color: {
                    dark: 'white',
                    light: 'status-critical',
                },
            },
            position: 'inner',
            side: 'bottom',
        },
        content: {
            pad: 'small',
        },
        disabled: {
            background: {
                color: 'status-disabled',
                opacity: 'medium',
            },
        },
        error: {
            color: 'status-critical',
            margin: {
                vertical: 'xsmall',
                horizontal: 'small',
            },
        },
        help: {
            color: 'dark-3',
            margin: {
                start: 'small',
            },
        },
        info: {
            color: 'text-xweak',
            margin: {
                vertical: 'xsmall',
                horizontal: 'small',
            },
        },
        label: {
            margin: {
                vertical: 'xsmall',
                horizontal: 'small',
            },
        },
        margin: {
            bottom: 'small',
        },
        round: '2px',
    },
    layer: {
        background: {
            dark: '#111111',
            light: '#FFFFFF',
        },
    },
};

const theTheme = deepMerge(grommet, base);

export default theTheme;
